import React, {createContext, useContext, useState} from 'react';

import {ContainerProps} from '../../ts/interfaces';

interface UserSettingsProviderProps extends ContainerProps {}

export type AccessibilityFontSize = 's' | 'm' | 'l' | 'xl';
interface UserSettingsContextData {
  darkmode: boolean;
  setDarkmode?: React.Dispatch<React.SetStateAction<boolean>>;
  chatBubbleColor: 'brown';
  setChatBubbleColor?: React.Dispatch<React.SetStateAction<'brown'>>;
  accessFontSize: AccessibilityFontSize;
  setAccessFontSize?: React.Dispatch<React.SetStateAction<AccessibilityFontSize>>;
}

const defaultState: UserSettingsContextData = {
  darkmode: false,
  chatBubbleColor: 'brown',
  accessFontSize: 'm',
};

const UserSettingsContext = createContext<UserSettingsContextData | any>(defaultState);

const UserSettingsProvider: React.FC<UserSettingsProviderProps> = ({children}) => {
  const [darkmode, setDarkmode] = useState<boolean>(defaultState.darkmode);
  const [chatBubbleColor, setChatBubbleColor] = useState<'brown'>(defaultState.chatBubbleColor);
  const [accessFontSize, setAccessFontSize] = useState<AccessibilityFontSize>(defaultState.accessFontSize);

  const context = {
    darkmode,
    setDarkmode,
    chatBubbleColor,
    setChatBubbleColor,
    accessFontSize,
    setAccessFontSize,
  };

  return <UserSettingsContext.Provider value={context}>{children}</UserSettingsContext.Provider>;
};

export const useUserSettings = (): UserSettingsContextData => {
  return useContext(UserSettingsContext) as UserSettingsContextData;
};

export default UserSettingsProvider;
