import React, {useCallback, useState} from 'react';

import {useGroq} from '../../models/context/Groq';
import {FirestoreID} from '../../models/context/Firestore';

import {submitConversation} from '../../firebase';

import Scene, {PersonalizedSceneProps} from '../Scene';
import History, {ChatMessage} from '../Chat/History';
import Input from '../Chat/Input';
import Navigation from '../Navigation';
import Sabotage from '../Background/Sabotage';
import Logo from '../Logo';
import Footer from '../Footer';

export const CONVERSTATION__FRESH_USER: ChatMessage[] = [
  {
    content:
      'Hi, ich bin ein virtueller Assistent von Sabotage-Tattoo und bin hier, um dir bei Fragen oder kostenlosen Beratungsterminen zur Seite zu stehen. Schreibe alles in die Box was dich interessiert. Chatte einfach mit mir!',
    role: 'assistant',
  },
];

function SceneIntroFreshUser({skipped}: PersonalizedSceneProps) {
  const {sendToGroq} = useGroq();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [conversationId, setConversationId] = useState<FirestoreID>();
  const [isConversationFinished, setIsConversationFinished] = useState<boolean>(false);
  const [isSceneRunning] = useState<boolean>(false);
  const [isInputActive] = useState<boolean>(true);

  const [messages, setMessages] = useState<ChatMessage[]>(CONVERSTATION__FRESH_USER);
  const [currentPrompt] = useState<string>('Mache einen Termin oder stelle uns deine Fragen 👨🏻‍💻');

  const addMessage = async (message: ChatMessage) => {
    setMessages(prev => [...prev, message]);
  };

  const onUserMessage = useCallback(
    async (value: string) => {
      addMessage({content: value, role: 'user'});
      if (!currentPrompt) {
        return;
      }

      const message = await sendToGroq(value);
      addMessage(message);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPrompt, sendToGroq],
  );

  const onEndConverstation = useCallback(async () => {
    if (isConversationFinished) {
      addMessage({content: 'Ihr Termin wird nun bearbeitet.', role: 'assistant'});
      return;
    }

    setIsLoading(true);
    submitConversation(conversationId);
    addMessage({content: 'Wir haben Ihre Nachricht erhalten. Ihr Termin wird nun bearbeitet.', role: 'assistant'});
    setIsConversationFinished(true);
    setIsLoading(false);
  }, [conversationId, isConversationFinished]);

  return (
    <Scene isRunning={isSceneRunning} background={<Sabotage />} onSkip={undefined} skipped={true} disableSkip>
      <Logo />
      <History
        messages={messages}
        conversationStartsAt={CONVERSTATION__FRESH_USER.length}
        isLoading={isLoading}
        onEndConversation={onEndConverstation}
        onConversationCreated={setConversationId}
        isConversationFinished={isConversationFinished}
        saveToFirestore
      />
      <Input active={isInputActive} prompt={currentPrompt} onSubmit={onUserMessage} />
      <Navigation routes={[{title: 'Zurück zur Website', path: '/'}]} />
      <Footer
        copyright="© Sabotage Tattoo 2024"
        datenschutz="https://sabotage-tattoo.com/datenschutzerklaerung/"
        impressum="https://sabotage-tattoo.com/impressum/"
      />
    </Scene>
  );
}

export default SceneIntroFreshUser;
