import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';

import './input.css';
import {useUserSettings} from '../../models/context/UserSettings';

import {ReactComponent as Send} from '../../assets/svg/send.svg';
import {ReactComponent as MicOn} from '../../assets/svg/microphone.svg';
import {ReactComponent as MicOff} from '../../assets/svg/microphone-off.svg';
import {Prompt} from '../../ts/types';
import Button from '../Button';

export interface InputProps {
  active: boolean;
  defaultValue?: string;
  prompt?: string | Prompt;
  onSubmit?: (value: string) => void;
}

function Input({active = false, defaultValue, prompt, onSubmit}: InputProps) {
  /*
  const [indexPreviousValue, setIndexPreviousValue] = useState<number>(-1);
  const [previousValues, setPreviousValues] = useState<string[]>([]);
  */
  const [value, setValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isRecognized, setIsRecognized] = useState<boolean>(false);
  const [isMicOn, setIsMicOn] = useState<boolean>(false);

  const {chatBubbleColor} = useUserSettings();

  const classNames = useMemo(() => {
    const classes = [];
    classes.push('input--wrapper');
    !active && classes.push('hidden');
    active && !isFocused && !isRecognized && classes.push('prompt');
    isFocused && classes.push('focused');
    return classes.join(' ');
  }, [active, isFocused, isRecognized]);

  const placeholder = !prompt ? '' : typeof prompt === 'string' ? prompt : prompt[0];

  const onSubmitAction = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (value: string) => {
      if (!value) {
        return;
      }

      //setPreviousValues((prev) => [...prev, value]);
      setValue('');
      onSubmit && onSubmit(value);
    },
    [onSubmit],
  );

  function activateMic() {
    const newState = !isMicOn;
    setIsMicOn(newState);

    if (!newState) {
      console.log('is OFF');
      return;
    }
    console.log('is ON');

    /*
    navigator.mediaDevices.getUserMedia({audio: true}).then(stream => {
      // You’ll add more code here later
      // const mediaRecorder = new MediaRecorder(stream);
    });
    */
  }

  const onHandleKeyDown = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
    event => {
      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          return onSubmitAction(value);
        /*
        case "ArrowUp":
          event.preventDefault();
          return setIndexPreviousValue((prev) => {
            const i = prev + 1;
            const len = previousValues.length - 1;
            return i > len ? len - 1 : i;
          });
        case "ArrowDown":
          event.preventDefault();
          return setIndexPreviousValue((prev) => {
            const i = prev - 1;
            if (i === -1) {
              setValue("");
            }
            return i < -1 ? -1 : i;
          });
          */
      }
    },
    [onSubmitAction, value],
  );

  useEffect(() => {
    defaultValue && setValue(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
  useEffect(() => {
    if (indexPreviousValue === -1) {
      return;
    }
    const rev = previousValues.reverse();
    rev[indexPreviousValue] && setValue(rev[indexPreviousValue]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexPreviousValue]);
  */

  useLayoutEffect(() => {
    !isRecognized && isFocused && setIsRecognized(true);
  }, [isFocused, isRecognized]);

  return (
    <div className={classNames}>
      <div className="input--inner">
        <form autoComplete="off">
          <input autoComplete="false" name="hidden" type="text" style={{display: 'none'}} />
          <input
            name="grog-user-input"
            className="input--user-input"
            value={value}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={({target}) => setValue((target as any).value)}
            onSubmit={({target}) => onSubmitAction((target as any).value)}
            onKeyDown={onHandleKeyDown}
            placeholder={placeholder}
          />
        </form>
        <Button
          icon={isMicOn ? <MicOn /> : <MicOff />}
          className="input--mic"
          onClick={activateMic}
          data-theme-color={chatBubbleColor}
          keepRound
        />
        <Button
          icon={<Send />}
          className="input--submit"
          onClick={() => onSubmitAction(value)}
          data-theme-color={chatBubbleColor}
          keepRound
        />
      </div>
    </div>
  );
}

export default Input;
