/* eslint-disable no-useless-escape, no-control-regex, @typescript-eslint/no-redeclare, @typescript-eslint/no-unused-vars */
export const FLAGS = {
  submit: `":conversation-submit"`,
  interrupt: `":conversation-interrupt"`,
  end: `":conversation-end"`,
};

export const VARS = {
  topics: '<Piercing/Tattoo>', // format <Topic1/Topic2/...>
  userName: '<user-name>',
  userEmail: '<user-email>',
  userPhone: '<user-phone>',
  userRequest: '<user-request>',
  date: '<appointment-date>',
  recap: '<conversation-recap>',
};

export const INFORMATION = {
  userName: `Name:${VARS.userName}`,
  userEmail: `Email:${VARS.userEmail}`,
  userPhone: `Telefon:${VARS.userPhone}`,
  date: `Datum:${VARS.date}`,
  topic: `Thema:${VARS.topics}`,
};

export const REGEX = {
  userName: /^Name:(.*)$/g,
  userEmail:
    /^Email:(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/g,
  userPhone: /^Telefon:(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g,
  date: /^Datum:(.*)$/g,
  topic: /^Thema:(.*)$/g,
};

export const TEXT_DISCLAIMER = `
Bitte beachten Sie, dass diese Konversation und Informationsaufnahme durch eine künstliche Intelligenz (KI) und nicht durch einen Menschen erfolgt. Die KI versucht, Ihnen bestmöglich zu helfen, aber es können dennoch Fehler passieren. Wir bitten Sie, nachsichtig mit eventuellen Unstimmigkeiten umzugehen.

Ihr Feedback ist uns sehr wichtig! Teilen Sie dem Sabotage Tattoo Team gerne positives und negatives Feedback zu Ihrer Erfahrung mit der KI mit. Ihre Rückmeldungen helfen uns, unseren Service kontinuierlich zu verbessern.

Vielen Dank für Ihr Verständnis und Ihre Unterstützung!
`;

export const TEXT_APPOINTMENT_CONFIRM = `
Ihr Termin wurd nun von unserem Team bearbeitet und Sie werden benachrichtigt, sobald der Termin bestätigt ist.
`;

export const TEXT_CLOSING = `
Ihr Sabotage Tattoo Team
`;
