import React, {useEffect} from 'react';
import {useCallback, useRef, useState, useLayoutEffect} from 'react';

import {FirestoreID, useFirestore} from '../../models/context/Firestore';
import {useUserSettings} from '../../models/context/UserSettings';
import {BusinessNetworkMember} from '../../ts/types';
import Bubble from './Bubble';

import './history.css';

export interface ChatMessage {
  content: string;
  role: BusinessNetworkMember | 'user';
  isLoud?: boolean;
  flags?: string[];
}

export interface ChatHistoryProps {
  messages: ChatMessage[];
  id?: FirestoreID;
  isLoading?: boolean;
  saveToFirestore?: boolean;
  conversationStartsAt?: number;
  onConversationCreated?: (conversationId: FirestoreID) => void;
  onEndConversation?: (conversation: ChatMessage[]) => void;
  isConversationFinished?: boolean;
}

function History({
  messages,
  id,
  isLoading,
  saveToFirestore,
  conversationStartsAt = 0,
  onConversationCreated,
  onEndConversation,
  isConversationFinished,
}: ChatHistoryProps) {
  const refScroll = useRef(null);
  const [scrollPosY, setScrollPosY] = useState<number>(0);
  const [prevOffsetHeight, setPrevOffsetHeight] = useState<number>(0);

  const [conversationId, setConversationId] = useState<FirestoreID>(id);
  const [conversationLength, setConversationLength] = useState<number>(0);

  const {add, update} = useFirestore();
  const {chatBubbleColor} = useUserSettings();

  const onBubbleShow = useCallback(
    (offsetHeight: number) => {
      setPrevOffsetHeight(offsetHeight);
      setScrollPosY(prev => prev + prevOffsetHeight);
    },
    [prevOffsetHeight],
  );

  const onMessageAddedAction = useCallback(async () => {
    const m = messages.slice(conversationStartsAt);
    if (saveToFirestore && m.length > 0) {
      conversationId
        ? update<ChatMessage[]>('conversations', conversationId, m)
        : add<ChatMessage[]>('conversations', m).then(id => {
            onConversationCreated(id);
            setConversationId(id);
          });
    }
  }, [add, conversationId, conversationStartsAt, messages, onConversationCreated, saveToFirestore, update]);

  const onEndConversationAction = useCallback(async () => {
    onEndConversation && onEndConversation(messages.slice(conversationStartsAt));
  }, [conversationStartsAt, messages, onEndConversation]);

  useLayoutEffect(() => {
    refScroll?.current?.scrollTo({top: scrollPosY, behavior: 'smooth'});
  }, [scrollPosY]);

  useEffect(() => {
    if (messages.length > conversationLength) {
      onMessageAddedAction();
      setConversationLength(messages.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationLength, messages]);

  useEffect(() => id && setConversationId(id), [id]);

  return (
    <div className="chat-history--wrapper">
      <div className="chat-history--inner" ref={refScroll}>
        {Object.entries(messages).map(([index, {content: text, role, isLoud, flags}]) => (
          <Bubble
            key={index}
            text={text}
            isLoud={isLoud}
            spokenBy={role}
            isLoading={isLoading}
            flags={flags}
            color={role === 'user' ? chatBubbleColor : undefined}
            onFadeIn={onBubbleShow}
            onEndConverstation={onEndConversationAction}
            isConversationFinished={isConversationFinished}
          />
        ))}
      </div>
    </div>
  );
}

export default History;
