import React, {createContext, useContext, useState} from 'react';

import {ContainerProps} from '../../ts/interfaces';

import {addDoc, collection, doc, getDocsFromServer, query, updateDoc} from 'firebase/firestore';
import {firestore} from '../../firebase';

import {Collection} from '../../ts/types';
import {ChatMessage} from '../../components/Chat/History';

export type FirestoreID = string;

export type AuthID = string;
export type PlatformUserID = AuthID;

export interface FirestoreEntry<T> {
  id: FirestoreID;
  collection: Collection;
  createdDate: number;
  createdBy?: AuthID;
  updatedDate: number;
  updatedBy?: AuthID;
  data: T;
}

interface FirestoreProviderProps extends ContainerProps {}

interface FirestoreContextData {
  conversations: FirestoreEntry<ChatMessage>[];
  add: <T>(c: Collection, data: T, createdBy?: AuthID) => Promise<FirestoreID>;
  update: <T>(c: Collection, id: FirestoreID, update: T, key?: string, updatedBy?: AuthID) => Promise<void>;
}

const defaultState: any = {
  conversations: [],
};

async function createEntry<T>(c: Collection, data: T, createdBy?: PlatformUserID) {
  const ref = await addDoc(collection(firestore, c), {data});
  const entry: FirestoreEntry<T> = {
    id: ref.id,
    collection: c,
    createdDate: +new Date(),
    createdBy: createdBy || null,
    updatedDate: +new Date(),
    updatedBy: createdBy || null,
    data,
  };
  return entry;
}

async function updateEntry<T>(entry: FirestoreEntry<T>) {
  await updateDoc(doc(firestore, entry.collection, entry.id), {...entry});
}

const FirestoreContext = createContext<FirestoreContextData | any>(defaultState);

const FirestoreProvider: React.FC<FirestoreProviderProps> = ({children}) => {
  const [conversations, setConversations] = useState<FirestoreEntry<ChatMessage>[]>(defaultState.conversations);

  async function update<T>(
    c: Collection,
    id: FirestoreID,
    data: T,
    key: string = 'data',
    updatedBy?: AuthID,
  ): Promise<void> {
    const u = {[key]: data, updatedDate: +new Date()} as any;
    updatedBy && (u.updatedBy = updatedBy);
    await updateDoc(doc(firestore, c, id), u);
    return;
  }

  async function add<T>(c: Collection, data: T, createdBy?: PlatformUserID): Promise<FirestoreID> {
    const entry = await createEntry(c, data, createdBy);
    await updateEntry(entry);
    updateCache();
    return entry.id;
  }

  const context = {
    conversations,
    add,
    update,
  };

  const updateCache = async () => {
    const q = query(collection(firestore, 'conversations'));
    getDocsFromServer(q).then(querySnapshot => {
      const conversations: any[] = [];
      querySnapshot.forEach(snapshot => conversations.push(snapshot.data()));
      setConversations(conversations);
    });
  };

  return <FirestoreContext.Provider value={context}>{children}</FirestoreContext.Provider>;
};

export const useFirestore = (): FirestoreContextData => {
  return useContext(FirestoreContext) as FirestoreContextData;
};

export default FirestoreProvider;
