import _ from 'lodash';
import { BusinessNetworkMember, Timing } from './types';

/**
 * Simple object check.
 * @param target
 * @returns {boolean}
 */
export const isObject = (target: any): boolean => {
  return target !== null && typeof target === 'object' && !Array.isArray(target);
};

/**
 *
 * @param target
 * @param key
 * @returns
 */
export const hasKey = (target: any, key: string) => {
  if (!isNaN(+key)) {
    return +key in target;
  }
  return isObject(target) && target.hasOwnProperty(key);
};

/**
 *
 * @param target
 * @param key
 * @returns
 */
export const getValueByKeyString = (target: any, key: string): any => {
  if (!key || !target) {
    return false;
  }

  // Split key string into array
  const keyArray = key.split('.');

  // Dig down target object to find previous value
  let value = target;
  for (const k of keyArray) {
    if (!hasKey(value, k)) {
      return value;
    }

    value = value[k];
  }

  return value;
};


/**
 *
 * @param prevProps
 * @param nextProps
 * @param compare
 * @returns
 */
export const isEqual = (prevProps: any, nextProps: any, compare: string[]): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  let isEqual = true;
  for (const key of compare) {
    const prev = getValueByKeyString(prevProps, key);
    const next = getValueByKeyString(nextProps, key);

    if (!_.isEqual(prev, next)) {
      isEqual = false;
      return false;
    }
  }
  return isEqual;
};

/**
 * 
 * @param ms 
 * @returns 
 */
export async function wait(ms: number) {
  return new Promise((resolve, _) => {setTimeout(resolve, ms)})
}

/**
 *
 * @param obj
 */
export const log = (obj: any) => {
  console.log('\n', JSON.stringify(obj, null, 4));
};

const TYPEWRITER__WORDS_PER_MINUTE = 216;
const TYPEWRITER__MIN_DURATION = (3 / TYPEWRITER__WORDS_PER_MINUTE) * 60000
const TYPEWRITER__BUFFER_DURATION = 181;
/**
 * 
 * @param text 
 * @returns 
*/
export const getTypewriterDuration = (text: string) => {
  const words = text.split(" ").length || 1;
  const duration = (words / TYPEWRITER__WORDS_PER_MINUTE) * 60000
  return (duration > TYPEWRITER__MIN_DURATION ? duration : TYPEWRITER__MIN_DURATION) + TYPEWRITER__BUFFER_DURATION;
}

export const getBusinessMemberName = (member: BusinessNetworkMember) => {
  switch (member) {
    case 'mihai':
    case 'raphael':
    case 'assistant':
    case 'system':
      return 'System';
    default:
      return false;
  }
}

export const timingFromNumber = (value: number | Timing): Timing => (typeof value === "number" ? `${value}ms` : value)
export const floatFromTiming = (timing: number | Timing): number => (typeof timing === "number" ? timing : parseFloat(timing))