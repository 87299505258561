import React from 'react';

import './logo.css';

export interface LogoProps {}

function Logo() {
  return (
    <a className="logo--wrapper" href="https://sabotage-tattoo.com">
      <img src={require('../assets/logos/sabotage-tattoo.png')} className="logo--image" alt="sabotage-tattoo--logo" />
    </a>
  );
}

export default Logo;
