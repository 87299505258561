import React from 'react';

import './footer.css';

export interface FooterProps {
  copyright: string;
  datenschutz: string;
  impressum: string;
}

function Footer({copyright, datenschutz, impressum}: FooterProps) {
  return (
    <div className="footer--wrapper">
      {!!copyright && <span className="footer--copyright">{copyright}</span>}
      <nav className="footer--navigation">
        {!!datenschutz && <a href={datenschutz}>Datenschutz</a>}
        {!!impressum && <a href={impressum}>Impressum</a>}
        <a href="https://aixlab.tech" target="_black">
          Made by
        </a>
      </nav>
    </div>
  );
}

export default Footer;
